'use client';
import { Box, Heading, Text } from '@chakra-ui/layout';
import { getImageProps } from 'next/image';
import Link from 'next/link';

import { DateService } from '@/services/date.service';
import { getImageLink } from '@/services/link.service';
import { directusImageLoader } from '@/utils/loaders';

import Styles from './NewsFeedCard.module.scss';

type NewsFeedCardProps = {
  date_to_publish: string;
  header_image: string;
  header_title: string;
};

const NewsFeedCard = ({
  date_to_publish,
  header_image,
  header_title,
}: NewsFeedCardProps) => {
  const common = {
    alt: 'news-feed-card',
    width: 400,
    height: 369,
  };

  const imageKeys = {
    mobileKey: 'image-312-312',
    desktopKey: 'image-400-369',
    loader: directusImageLoader,
  };

  const imagesrc = getImageLink(header_image as string);

  const {
    props: { srcSet: mobile },
  } = getImageProps({
    ...common,
    src: `${imagesrc}?key=${imageKeys.mobileKey}`,
  });

  const {
    props: { srcSet: desktop, ...rest },
  } = getImageProps({
    ...common,
    src: `${imagesrc}?key=${imageKeys.desktopKey}`,
  });

  return (
    <>
      <Box className={Styles.newsFeedCard}>
        <Link href={''} className={Styles.newsFeedImgBlock}>
          <picture className={Styles.newsFeedImage}>
            <source media="(max-width: 575px)" srcSet={mobile} />
            <source media="(min-width: 576px)" srcSet={desktop} />
            <img {...rest} alt={common.alt} />
          </picture>
        </Link>
        <Box
          className={Styles.headingElement}
          paddingX={{ base: 6 }}
          paddingY={{ base: 10 }}
        >
          <Heading
            as="h4"
            className={Styles.titleSm}
            marginBottom={{ base: 4, md: 5 }}
            color={'white'}
          >
            <Link href={''}>{header_title}</Link>
          </Heading>
          <Text className={Styles.titleText} color={'white'}>
            {DateService.formatDate(date_to_publish)}
          </Text>
        </Box>
      </Box>
    </>
  );
};

export default NewsFeedCard;
